import { FC } from "react";
import {
  BrowserRouter as Router,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "redux/store";
import { ROUTES } from "utils/constants/router";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { env } from "config/env";
import NotFound from "pages/NotFound";
import { useScript } from "./core/app.hooks";
import { endpoints } from "config/endpoints";
import { isDev } from "utils/index";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

if (!isDev()) {
  Sentry.init({
    dsn: env.sentryDSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const App: FC = () => {
  useScript({
    scriptUrl: `${env.libBaseUrl}/${endpoints.api.events.libPath}`,
    onScriptLoaded: () => {
      myli_push
        .init("Mylottery", window.location.host)
        .systeminfo()
        .pageview({});
    },
  });

  const { t } = useTranslation("head");
  console.log("t", t("title"), t("description"));

  return (
    <Provider store={store}>
      <Helmet>
        <title>{t("title")}</title>
        {/* <!-- Primary Meta Tags --> */}
        <meta name="title" content={t("title")} />
        <meta name="description" content={t("description")} />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="http://lottery.staging.myli.xyz/7b5191b0-a538-11ec-baaf-4201ac1c202e"
        />
        <meta property="og:title" content={t("title")} />
        <meta property="og:description" content={t("description")} />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="http://lottery.staging.myli.xyz/7b5191b0-a538-11ec-baaf-4201ac1c202e"
        />
        <meta property="twitter:title" content={t("title")} />
        <meta property="twitter:description" content={t("description")} />
      </Helmet>
      <Router>
        <Routes>
          <Route path="/" element={<Outlet />}>
            <Route path="/" element={<NotFound />} />
            <Route path="404" element={<NotFound />} />
            <Route path=":entityId" element={<Outlet />}>
              {Array.from(ROUTES.values()).map((route) => (
                <Route key={route.path} path={route.path} element={route.page}>
                  {route.nestedRoutes &&
                    Array.from(route.nestedRoutes.values()).map((route) => (
                      <Route
                        key={route.path}
                        path={route.path}
                        element={route.page}
                      />
                    ))}
                </Route>
              ))}
            </Route>
          </Route>
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;
